export const environment = {
	production: false,
	local: false,

	baseCommitmentUrl_i: 'https://wholesale-commitment-service-stg.apps.pd01i.edc1.cf.ford.com',
	baseCommitmentUrl_e: 'https://wholesale-commitment-service-stg.apps.pd01e.edc1.cf.ford.com',
	baseProductionOfferUrl_i: 'https://production-offer-service-stg.apps.pd01i.edc1.cf.ford.com',
	baseProductionOfferUrl_e: 'https://production-offer-service-stg.apps.pd01e.edc1.cf.ford.com',
	baseWholesaleAllocationUrl_i: 'https://wholesale-allocation-service-uat.apps.pp01i.edc1.cf.ford.com',
	baseWholesaleAllocationUrl_e: 'https://wholesale-allocation-service-uat.apps.pp01e.edc1.cf.ford.com',
	baseDealerServiceUrl_i: 'https://dealer-details-service-prod.apps.pd01i.edc1.cf.ford.com',
	baseDealerService_e: 'https://dealer-details-service-prod.apps.pd01e.edc1.cf.ford.com',
	adfsBaseUrl: 'https://fd-usr-sso.login.sys.pd01.edc1.cf.ford.com',
	oauthUrl: 'https://fd-usr-grp-sso.login.sys.pd01.edc1.cf.ford.com/oauth/authorize?',
	client_id: '1c86b284-2d2c-4a59-98d1-14b5463ad8e5',

	baseBulkOrderCreationUrl_i: '',
	baseBulkOrderCreationUrl_e: '',
	redirectUrl_i: 'https://wwwstg.gws.ford.com',
	redirectUrl_e: 'https://wwwstg.gws.dealerconnection.com',

	saveVolumeCommitUrl: '/api/v1/volume-level-commitment',
	getAllVolumeCommitUrl: '/api/v1/vehicleline-volume-commitment',
	getWholesaleStatusUrl: '/api/v1/wholesaleStatus',
	getDealerDetailsUrl: '/api/v1/dealer-volume-commitment',
	getDealerGroupsUrl: '/api/v1/dealer-groups',
	getProductionOfferUrl:'/api/v1/get-production-offers',
	getDealerDetailUrl: '/api/v1/get-dealer-details',
	getDealerIdlistUrl: '/api/v1/dealer-ids',
	postEmailServiceUrl: '/web/v1/send-message',
	getDistrictAndZoneListUrl: '/api/v1/market-districts-zones',
	getMessageUrl: '/api/v1/message-broadcast',
	getVolumeCommitDataForAudit: '/api/v1/volume-commitment-by-market',

	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	openidClientId: 'urn:globalwholesalewebui:clientid:web_gwsdevwebsite:dev',
	openidResource: 'urn:globalwholesalewebui:resource:web_gwsdevwebsite:dev'
};
